import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Box, TextField, Button, Link, Tooltip, Grid } from "@mui/material"
import LoginError from "../../../components/LoginError"
import { userLogin, userRegister } from "../../../fetch/login"
import { F_SCHOOLHUNTING, F_TRADING, F_VERWALTUNG } from "../../../constants/routes"
import UserContext from "../../../context/UserContext"
import SettingsContext from "../../../context/SettingsContext"
import LicenceContext from "../../../context/LicenceContext"

const sha256 = require("js-sha256")

const hashing = (password) => {
  return sha256(password)
}

const LoginForm = ({ email, setEmail, password, setPassword, switchPage, setCurrentPage }) => (
  <>
    <Link
      variant="caption"
      href="#"
      sx={{
        color: "gray",
        textDecoration: "none",
      }}
      onClick={() => switchPage("Register")}>
      Noch kein Account?
    </Link>
    <TextField
      label="Email / Nutzername"
      margin="normal"
      required
      fullWidth
      id="email"
      name="email"
      autoComplete="email"
      autoFocus
      onChange={(e) => setEmail(e.target.value)}
      variant="outlined"
      sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
    />
    <Link
      variant="caption"
      onClick={() => setCurrentPage("forgot")}
      sx={{
        color: "gray",
        textDecoration: "none",
        cursor: "pointer",
      }}>
      Passwort vergessen?
    </Link>
    <TextField
      label="Passwort"
      margin="normal"
      required
      fullWidth
      name="password"
      type="password"
      id="password"
      autoComplete="current-password"
      onChange={(e) => setPassword(e.target.value)}
      sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
    />
    <Button
      type="submit"
      fullWidth
      disabled={!(email.length > 0 && password.length > 0)}
      sx={{ mt: 3, mb: 2, color: "white", backgroundColor: "primary.main", height: "3.5rem" }}>
      Login
    </Button>
  </>
)

const RegisterForm = ({ schoolcode, setSchoolcode, email, setEmail, password, setPassword, setCurrentPage, isMobile }) => (
  <>
    {/* <Typography paragraph align="center" sx={{ cursor: "pointer" }} color="primary.main" onClick={() => setCurrentPage("Lizenzen und Module")}>
      Registrierung für Schulen nur über <u>Lizenzen und Module</u>
    </Typography> */}
    {/* <Typography paragraph variant="subtitle1" align="center">
      Registrierung für Schüler
    </Typography> */}
    <Tooltip title="Den Schulcode erhälst Du von Deiner Schule" arrow placement="bottom">
      <Link
        variant="caption"
        href="#"
        sx={{
          color: "gray",
          textDecoration: "none",
        }}>
        {isMobile ? "Den Schulcode erhälst Du von Deiner Schule" : " Wo finde ich meinen Schulcode?"}
      </Link>
    </Tooltip>
    <TextField
      label="Schulcode"
      autoFocus
      margin="normal"
      required
      fullWidth
      id="schulcode"
      name="schulcode"
      onChange={(e) => setSchoolcode(e.target.value)}
      variant="outlined"
      sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
    />
    <TextField
      margin="normal"
      label="Email"
      required
      fullWidth
      id="email"
      name="email"
      onChange={(e) => setEmail(e.target.value)}
      variant="outlined"
      sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
    />
    <TextField
      margin="normal"
      label="Passwort"
      required
      fullWidth
      name="password"
      type="password"
      id="password"
      onChange={(e) => setPassword(e.target.value)}
      sx={{ backgroundColor: "white", height: "3.5rem", borderRadius: "0.3rem" }}
    />
    <Button
      type="submit"
      fullWidth
      disabled={!(email.length > 0 && password.length > 0 && schoolcode.length > 0)}
      sx={{ mt: 3, mb: 2, color: "white", backgroundColor: "primary.main", height: "3.5rem" }}>
      Registrieren
    </Button>
  </>
)

const Login = ({ setCurrentPage, isMobile }) => {
  const navigate = useNavigate()
  const { userData, setUserDataExternal } = useContext(UserContext)
  const { setSettingsExternal } = useContext(SettingsContext)
  const { setLicenceDataExternal } = useContext(LicenceContext)
  const [showLoginError, setShowLoginError] = useState("none")
  const [errorMsg, setErrorMsg] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [schoolcode, setSchoolcode] = useState("")
  const [activePage, setActivePage] = useState("Login")

  useEffect(() => {
    const isLogged = userData?.Email
    console.log(isLogged)
    if (isLogged) {
      const navLog = userData?.role === "Normal" ? F_TRADING : F_VERWALTUNG
      navigate(navLog)
    }
    // eslint-disable-next-line
  }, [userData])

  const switchPage = (page) => {
    setErrorMsg("")
    setShowLoginError("none")
    setActivePage(page)
    setEmail("")
    setPassword("")
    setSchoolcode("")
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    try {
      const hash = hashing(password)
      const user = await userLogin({ email, password: hash })

      if (user.error) {
        setShowLoginError("inherit")
        setErrorMsg(user.message)
      } else {
        setUserDataExternal(user)
        setSettingsExternal({ defaultTradingPage: "Bücherlisten" })
        const modTemp = user?.module
        const modTemp2 = Object.keys(modTemp).map((key) => modTemp[key])

        const modDatenTemp = user?.modulDaten
        const modDatenTemp2 = Object.keys(modDatenTemp).map((key) => modDatenTemp[key])

        const licDaten = {
          name: user?.Name,
          preis: user?.Preis,
          beschreibung: user?.Beschreibung,
          datum: user?.Datum,
          bezahlt: user?.Bezahlt,
        }
        const licences = {
          lizenz: user?.Config,
          lizenzDaten: licDaten,
          module: modTemp2,
          modulDaten: modDatenTemp2,
        }
        setLicenceDataExternal(licences)
        const nav = user?.role === "Schuladmin" ? F_VERWALTUNG : user?.role === "Normal" ? F_TRADING : F_SCHOOLHUNTING
        navigate(nav)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const handleRegister = async (event) => {
    event.preventDefault()
    try {
      const hash = hashing(password)
      const user = await userRegister({ schoolcode, email, password: hash })

      if (user.error) {
        setShowLoginError("inherit")
        setErrorMsg(user.message)
      } else {
        localStorage.setItem("validateEmail", JSON.stringify(user))
        setCurrentPage("validate")
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <Grid container>
      <Grid item xs={1} md={4}>
        <Box></Box>
      </Grid>
      <Grid item xs={10} md={4}>
        <Box
          component="form"
          onSubmit={activePage === "Login" ? handleLogin : handleRegister}
          noValidate
          sx={{
            mx: "auto",
            mt: "5vh",
            mb: activePage === "Login" ? "6vh" : "0",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "primary.main",
            borderRadius: "12px",
            p: "5vh",
            width: "100%",
          }}>
          <Box sx={{ mb: "3vh", display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "1vw" }}>
            <Button
              onClick={() => {
                switchPage("Register")
              }}
              variant="outlined"
              sx={{
                fontSize: "smaller",
                backgroundColor: activePage !== "Login" ? "primary.main" : "white",
                color: activePage !== "Login" ? "white" : "primary.main",
              }}>
              Registrieren
            </Button>
            <Button
              onClick={() => {
                switchPage("Login")
              }}
              variant="outlined"
              sx={{
                fontSize: "smaller",
                backgroundColor: activePage !== "Register" ? "primary.main" : "white",
                color: activePage !== "Register" ? "white" : "primary.main",
              }}>
              Login
            </Button>
          </Box>

          {activePage === "Login" && (
            <LoginForm
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              switchPage={switchPage}
              setCurrentPage={setCurrentPage}
            />
          )}

          {activePage === "Register" && (
            <RegisterForm
              schoolcode={schoolcode}
              setSchoolcode={setSchoolcode}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              setCurrentPage={setCurrentPage}
              isMobile={isMobile}
            />
          )}

          <LoginError msg={errorMsg} visible={showLoginError} />
        </Box>
      </Grid>
      <Grid item xs={1} md={4}>
        <Box></Box>
      </Grid>
    </Grid>
  )
}

export default Login
