import React from "react"
import { Grid, Typography, Paper } from "@mui/material"
import MainFeaturedPost from "./MainFeaturedPost"
import FeaturedPost from "./FeaturedPost"
import Main from "./Main"
import Sidebar from "./Sidebar"

import image from "./img/ver2.png"
import plakatimg from "./img/plakat.jpg"
// import appimg from "./img/schueler2.png"
// import lizenzimg from "./img/lizenzNeu.png"
// import bmbf from "./img/bmbf/bmbf.png"
import bmbfClipped from "./img/bmbf/bmbfClipped.png"

const bmbfPost = {
  title: "Neu: Förderung vom BMBF",
  date: "2024",
  description: "Bookay wird nun offiziell vom Bundesministerium für Bildung und Forschung gefördert.",
  image: bmbfClipped,
  imageLabel: "bmbf",
  // pageName: "Von Schülern - Für Schüler",
  highlight: true,
}

const featuredPosts = (isMobile) => [
  bmbfPost,
  {
    title: "Von Schülern - Für Schüler",
    date: "Seit 2021",
    description: "Gestartet als Unterrichtsprojekt, nun ein Berliner Schüler-Startup - Gib deine Bücher up!",
    image: plakatimg,
    imageLabel: "Students",
    pageName: "Von Schülern - Für Schüler",
  },
  // {
  //   title: "Die Lösung für Ihr Büchersystem",
  //   date: "Bookay Web App",
  //   description: "Bookay hilft der Schulverwaltung und den Schülern, ihre Schulbücher schneller und günstiger zu verwalten.",
  //   image: appimg,
  //   imageLabel: "Solution",
  //   pageName: "Bookay Web App",
  // },
  // {
  //   title: "Preise",
  //   date: "Lizenzen und Module",
  //   description: "Sie wählen Ihr passendes System: Orientierung nach Anzahl der Schüler oder individuelle Extra-Funktionen.",
  //   image: lizenzimg,
  //   imageLabel: "Licences",
  //   pageName: "Lizenzen und Module",
  // },
]

const posts = [
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Schulbuchverwaltung mit Bookay
    </Typography>
    <Typography paragraph>
      Bookay ist eine von Schülern erstellte Schulplattform für digitale Schulbuchverwaltung. Erschaffen Sie einen geschlossenen Buchzyklus, um Ihrer
      internen Verwaltung Zeit und Ihren Schülern Geld zu sparen!
    </Typography>
  </Paper>,
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Jährlicher Bücherkauf und -verkauf an einem Ort
    </Typography>
    <Typography paragraph>
      Schüler kaufen hier ihre Bücher für das nächste Schuljahr von einem Mitschüler des älteren Jahrgangs ab - <br />
      Und verkaufen ihre alten Bücher einfach an einen Mitschüler des jüngeren Jahrgangs.
    </Typography>
    <Typography paragraph>Ab jetzt decken sich die Kosten für die Bücher des nächsten Jahres durch den Verkauf der alten Bücher!</Typography>
    <Typography paragraph>Durch eine einfache Suche finden sich zwei interessierte Partner und verständigen sich über unseren Chat.</Typography>
  </Paper>,
  <Paper
    elevation={0}
    sx={{
      my: 2,
      p: 2,
      bgcolor: "grey.200",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
    }}>
    <Typography variant="h6" gutterBottom paragraph>
      Wieder neue Bücherlisten erstellen?
    </Typography>
    <Typography paragraph>
      Durch digitale Schulbuchverwaltung bringen Sie Ordnung in das Chaos von Bücherbasaren und spontanen Bücher-Flohmärkten an Ihrer Schule.
    </Typography>
    <Typography paragraph>
      Mit schulspezifischen Funktionen wie eigenen Fächern, QR-Codes auf den Bücherlisten und vielem mehr passt sich die Web-App an Ihre spezifischen
      Bedürfnisse an.
    </Typography>
  </Paper>,
]

const Landing = ({ setCurrentPage, isMobile }) => {
  const mainFeaturedPost = isMobile
    ? {
        title: "---------------------",
        description: "---------------------",
        image: image,
        imageText: "Main Image",
        linkText: "---------------------",
      }
    : {
        title: "--------------------------------- ----------------------------------------------",
        description: "--------------------------------- ----------------------------------------------",
        image: image,
        imageText: "Main Image",
        linkText: "--------------------------------- ----------------------------------------------",
      }

  return (
    <>
      {/* {!isMobile && <FeaturedPost disableLink setCurrentPage={setCurrentPage} isMain={true} post={bmbfPost} />} */}
      <MainFeaturedPost hidden post={mainFeaturedPost} landing isMobile={isMobile} />
      <Grid container spacing={4}>
        {featuredPosts(isMobile).map((post, index) => (
          <FeaturedPost
            setCurrentPage={setCurrentPage}
            showImgOnMobile={index === 0}
            objectFit={index === 0}
            isMain={[-1].includes(index)}
            key={post.title}
            post={post}
          />
        ))}
      </Grid>
      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Main title="Die von Schülern geführte Schulbuchverwaltung" posts={posts} />
        <Sidebar setCurrentPage={setCurrentPage} />
      </Grid>
    </>
  )
}

export default Landing
