import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

import UserContext from "../../context/UserContext"

import Header from "./Header"
import Footer from "./Footer"

import Landing from "./Landing"
// import AppInfo from "./AppInfo/AppInfo"
// import Licences from "./Licences/Licences"
import Partner from "./Partner/Partner"
import Checkout from "./Checkout/Checkout"
import About from "./About/About"
import Impressum from "./Impressum/Impressum"
import Datenschutz from "./Impressum/Datenschutz"
import AGB from "./Impressum/AGB"
// import Kontakt from "./Kontakt/Kontakt"
import Login from "./Login/Login"
import ValidateEmail from "./ValidateEmail/ValidateEmail"
import ForgotPassword from "./ForgotPassword/ForgotPassword"
import CookieFormular from "./CookieFormular"

const tabs = {
  "Von Schülern - Für Schüler": About,
  // "Bookay Web App": AppInfo,
  Partnerschulen: Partner,
  // "Lizenzen und Module": Licences,
  // Kontakt: Kontakt,
}

const pages = {
  ...tabs,
  landing: Landing,
  impressum: Impressum,
  datenschutz: Datenschutz,
  agb: AGB,
  checkout: Checkout,
  login: Login,
  forgot: ForgotPassword,
  validate: ValidateEmail,
  validateCheckout: ForgotPassword,
}

const Start = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [currentPage, setCurrentPage] = useState("landing")
  // const [showLoginField] = useState(isMobile)
  const showLoginField = isMobile
  const { userData } = useContext(UserContext)

  const CurrentPageElement = (props) => {
    const TheComponent = currentPage ? pages[currentPage] : pages["landing"]
    return <TheComponent isMobile={isMobile} {...props} />
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const param = searchParams.get("page")
    if (param) {
      if (param === "lizenzen") {
        setCurrentPage("Lizenzen und Module")
      } else {
        setCurrentPage(param)
      }
      navigate("/start")
    }
    const c = searchParams.get("c")
    if (c) {
      localStorage.setItem("c", c)
    }
    // setTimeout(() => {
    //   setShowLoginField(true) // show the field after 2 secs, removed now
    // }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container maxWidth="lg" role="main">
        <Header tabs={tabs} userData={userData?.role === "Schuladmin" ? userData : null} setCurrentPage={setCurrentPage} />
        {showLoginField && currentPage !== "login" && currentPage !== "validate" && currentPage !== "forgot" && currentPage !== "validateCheckout" ? (
          <Box
            onClick={() => setCurrentPage("login")}
            sx={{
              width: "100%",
              height: "auto",
              backgroundColor: "rgba(152, 227, 156, 0.4)",
              border: "2px solid",
              borderColor: "primary.main",
              borderRadius: "4px",
              my: 2,
              p: 2,
            }}>
            <Typography align="center" sx={{ display: "inline-flex", justifyContent: "center", width: "100%", cursor: "pointer" }}>
              Zur App <KeyboardArrowRightIcon />
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <CurrentPageElement
          isSchoolForgotPW={currentPage === "validateCheckout"}
          userData={userData?.role === "Schuladmin" ? userData : null}
          setCurrentPage={setCurrentPage}
        />
      </Container>
      <Footer title="Bookay 2024" setCurrentPage={setCurrentPage} />
      <CookieFormular />
    </>
  )
}

export default Start
